import React, { useState, useEffect } from "react";
import SideBar from "./components/sidebar";
import TopBar from "./components/topbar";
import Home from "./components/Home";
import Aurudition from "./components/Aurudition";
import Bizon from "./components/Bizon";
import Tilesetify from "./components/Tilesetify";
import Charcount from "./components/Charcount";
import './styles/styles.css';

function App() {
  const [showPage, setshowPage] = useState(sessionStorage.getItem('showPage') || 'Home');
  const [pages] = useState(
    {
      'Home': {
        name: 'Home',
        address: '',
      },
      'Aurudition': {
        name: 'Aurudition',
        address: '/aurudition',
      },
      'Bizon': {
        name: 'Multiplayer Bizongames',
        address: '/bizongames',
      },
      'Tilesetify': {
        name: 'Tilesetify',
        address: '',
      },
      'Charcount': {
        name: 'Charcount',
        address: '/charcount',
      },
    }
  );
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [windowHeight, setwindowHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setwindowWidth(window.innerWidth);
    setwindowHeight(window.innerHeight);
  }

  let isVertical: boolean = (windowWidth/windowHeight < 1);
  let isMobile: boolean = (windowWidth <= 1000);

  const Styles = {
    wrapper: {
      minHeight: isVertical ? windowHeight-60 : isMobile? windowHeight-50 : windowHeight-60, // windowHeight - navbar's height
      marginBottom: -50,
    },

    mainContent: {
      textAlign: 'justify',
      padding: '2%',
      backgroundColor: '#c9c9c9',
      borderRadius: 8,

      marginTop: isVertical ? 60 : isMobile? 50 : 60,
      marginBottom: isVertical ? 15 : isMobile? 15 : 20,
      marginLeft: '8%',
      marginRight: '8%',
    },
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function resetPage(page) {
    setshowPage(page);
    window.scrollTo({ top: 0, behavior: 'auto', });
  }

  return (
    <div className="App">
      {
        isVertical ?
          <SideBar
            pageWrapId={"page-wrap"}
            outerContainerId={"App"}

            pages={pages}
            setshowPage={(showPage) => {
              resetPage(showPage);
              sessionStorage.setItem('showPage', showPage);
            }}
          />
        :
          <TopBar
            pages={pages}
            setshowPage={(showPage) => {
              resetPage(showPage);
              sessionStorage.setItem('showPage', showPage);
            }}
          />
      }

      <div style={Styles.wrapper}>
        <div style={Styles.mainContent}>
          {
            showPage === pages["Home"].name ?
              <Home
                pages={pages}
                setshowPage={(showPage) => {
                  resetPage(showPage);
                  sessionStorage.setItem('showPage', showPage);
                }}
              />
            :
              showPage === pages["Aurudition"].name ?
                <Aurudition
                  page={pages['Aurudition']}
                />
              :
                showPage === pages["Bizon"].name ?
                  <Bizon
                    page={pages['Bizon']}
                  />
                :
                  showPage === pages["Tilesetify"].name ?
                    <Tilesetify
                      page={pages['Tilesetify']}
                    />
                  :
                    showPage === pages["Charcount"].name ?
                      <Charcount
                        page={pages['Charcount']}
                      />
                    :
                      <div></div>
          }
        </div>

        <div className="push"></div>
      </div>

      <footer className="footer">
        Copyright © Samuel LUC, {new Date().getYear()+1900}
      </footer>
    </div>
  );
}

export default App;
