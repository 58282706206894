import React, { useState, useEffect } from "react";
import '../styles/styles.css';

export default function Bizon(props) {
	const [state, setstate] = useState(props);

	useEffect(() => {
		setstate(props);
	}, [props]);

	return (
		<div>
			<div className='textaligncenter'>
				<img className='logo' alt='' src="./img/bizongames/logo192.png" />
				<p/>

				<a href={state.page.address} target="_blank" rel="noreferrer">
					<button className="button" type="submit">{state.page.name}</button>
				</a>

				<div className='pButton'>
					<p/>
					The Bizon is a 3-player trick-taking card game my friends and I designed together while on a bicycle trip across
					Europe in 2012. I then developed this online multiplayer version a couple of years afterwards.
					It is played much in the same fashion as the popular French card game Belotte, albeit easier to learn for beginners.
					Its most peculiar trait is as I have just said, that it is designed for exactly 3 players, while most other
					trick-taking games require exactly 4 (or sometimes 6) as a general rule.
					<p/>
					<div className='textaligncenter'><img alt='' className="screenIMG" src="./img/bizongames/bizonscreen1-min.png" /></div>
					<p/>
					I typed down the rules a couple of years ago, both in English and in French (though the game itself is
					also playable in Chinese). I simplified the way the points were counted afterwards, but didn't edit the PDF
					files to account for this change. So if you read them, the point counting system is much simpler now as there
					are only GP left: there are no SP anymore, but you'll have to discover what they were replaced with by
					yourself while playing. The PDF files for the rules are as follow:

					<div className="textaligncenter">

						<a
							href={`${process.env.REACT_APP_API_ENDPOINT}/download/bizon-en-simplified-guide.pdf`}
							download="bizon-en-simplified-guide.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							English
						</a>
						<br/>
						<a
							href={process.env.REACT_APP_API_ENDPOINT+'/download/bizon-fr-guide-simplife.pdf'}
							target="_blank"
	 						rel="noopener noreferrer"
						>
							Français
						</a>
					</div>

					The second game in this set, whose name must not be spoken, however, is a popular
					drinking game without a fixed number of players, though a minimum of three is recommended. I developed this
					online version for a friend who was getting bored during the 2020-2021 covid lockdowns. This game's rules are
					fairly easy to find on the internet as it it very popular with students in various regions of the world.
					<p/>
					<div className='textaligncenter'><img alt='' className="screenIMG" src="./img/bizongames/FTDscreen1-min.png" /></div>
				</div>

				<p/>
				<a href={state.page.address} target="_blank" rel="noreferrer">
					<button className="button" type="submit">{state.page.name}</button>
				</a>
			</div>
		</div>
	)
}
