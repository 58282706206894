import React, { useState, useEffect } from "react";
import '../styles/styles.css';

export default function Aurudition(props) {
  const [state, setstate] = useState(props);

  useEffect(() => {
    setstate(props);
  }, [props]);

  return (
    <div>
      <div className='textaligncenter'>
        <img className='logo' alt='' src="./img/charcount/logocharcount.png" />
        <p/>

        <a /*href={state.page.address}*/ target="_blank" rel="noreferrer">
          <button className="button" type="submit">{state.page.name} (currently under maintenance)</button>
        </a>

        <div className='pButton'>
          <p/>
          {state.page.name} is a Chinese text analysis tool. Due to its nature and the functionalities it provides you will need to register an account in order to use it.
          <p/>
          <div className='textaligncenter'>
            <img alt='' className="screenIMG" src="./img/charcount/charcountimg1-min.png" />
          </div>
          <p/>
          You can use it to create vocabulary lists according to the characters you already know, or add annotations to a text of your choice and underline key vocabulary words inside this annoted text.
          <br/>
          Please note that this project is still under development, especially on the side of the user interface, and that the current design is by no means final.
          I also intend to add functionalities such as the possibility to change the color of the tones within the annotated text, as well as an options sub-menu, and a bit more explanations overall as to how it works and how to best use it.
          <p/>
          Github: <a href="https://github.com/Kamille-Ligula/charcount" target="_blank" rel="noreferrer">https://github.com/Kamille-Ligula/charcount</a>
        </div>

        <p/>
        <a /*href={state.page.address}*/ target="_blank" rel="noreferrer">
          <button className="button" type="submit">{state.page.name} (currently under maintenance)</button>
        </a>
      </div>
    </div>
  )
}
