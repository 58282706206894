import React, { useState, useEffect } from "react";

export default function TopBar(props) {
  const [state, setstate] = useState(props);

  useEffect(() => {
    setstate(props);
  }, [props]);

  return (
    <div>
      <div className="topnav">
        {
          Object.entries(state.pages).map(([key, item]) => (
            <span
              key={item.name}
              className="topnavlinks"
              onClick={() => { props.setshowPage(item.name) }}
            >
              {item.name}
            </span>
          ))
        }
      </div>
    </div>
  )
}
