import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";

export default function SideBar(props) {
  const [state, setstate] = useState(props);
  const [menuOpen, setmenuOpen] = useState(false);

  useEffect(() => {
    setstate(props);
  }, [props]);

  function handleOnOpen() {
    setmenuOpen(true)
  }

  function handleOnClose() {
    setmenuOpen(false)
  }

  return (
    // Pass on our props
    <div style={{
      backgroundColor: '#333',
      width: '100%',
      height: 50, // a bit more than burger width (36)
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
    }}>
      <img
        onClick={() => { props.setshowPage(state.pages["Home"].name); }}
        style={{position: 'fixed', right: 10, top: 4, width: 126, height: 42}}
        alt=''
        src="./img/logo/full2white.png"
        className='mainlogo'
      />
      <Menu isOpen={menuOpen} onOpen={handleOnOpen} onClose={handleOnClose} width={'90%'} {...props}>
        {
          Object.entries(state.pages).map(([key, item]) => (
            <span key={item.name} onClick={() => { props.setshowPage(item.name); handleOnClose() }}>
              {item.name}
            </span>
          ))
        }
      </Menu>
    </div>
  );
};
