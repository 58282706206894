import React, { useState, useEffect } from "react";
import '../styles/styles.css';

export default function Home(props) {
  const [state, setstate] = useState(props);

  useEffect(() => {
    setstate(props);
  }, [props]);

  return (
    <div>
      <img alt='' src="./img/logo/full2.png" className='mainlogo' />
      <div className='pButton'>Mi Ranteule ("My Web" in English) is the crossroads where I store my projects and creations,
        some of which are single-page apps, while others are utility programs.
        <p/>So which one(s) are you looking for?
        <p/>
        <table className='projectLinksTable'>
          <tbody>
            <tr>
              <td>
                <div className="hoverimg" onClick={() => { props.setshowPage(state.pages["Aurudition"].name) }}>
                  <img alt='' className="projectLinksIMGS" src="./img/aurudition/auruditionscreen1-min.png" />
                  <br/>
                  <span className="screens">{state.pages["Aurudition"].name}</span>
                </div>
              </td>
              <td>
                <div className="hoverimg" onClick={() => { props.setshowPage(state.pages["Bizon"].name) }}>
                  <img alt='' className="projectLinksIMGS" src="./img/bizongames/bizonscreen1-min.png" />
                  <br/>
                  <span className="screens">{state.pages["Bizon"].name}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="hoverimg" onClick={() => { props.setshowPage(state.pages["Tilesetify"].name) }}>
                  <img alt='' className="projectLinksIMGS" src="./img/tilesetify/screen8enem-min.png" />
                  <br/>
                  <span className="screens">{state.pages["Tilesetify"].name}</span>
                </div>
              </td>
              <td>
                <div className="hoverimg" onClick={() => { props.setshowPage(state.pages["Charcount"].name) }}>
                  <img alt='' className="projectLinksIMGS" src="./img/charcount/charcountimg1-min.png" />
                  <br/>
                  <span className="screens">{state.pages["Charcount"].name}</span>
                </div>
              </td>
            </tr>
            {/*<tr>
              <td colSpan="2">
                <div className="hoverimg" onClick={() => { props.setshowPage(state.pages["Tilesetify"].name) }}>
                  <img alt='' className="projectLinksIMGShalfsize" src="./img/tilesetify/screen8enem-min.png" />
                  <br/>
                  <span className="screens">{state.pages["Tilesetify"].name}</span>
                </div>
              </td>
            </tr>*/}
          </tbody>
        </table>
      </div>
    </div>
  )
}
