import React, { useState, useEffect } from "react";
import '../styles/styles.css';

export default function Aurudition(props) {
  const [state, setstate] = useState(props);

  useEffect(() => {
    setstate(props);
  }, [props]);

  return (
    <div>
      <div className='textaligncenter'>
        <img className='mainlogo' alt='' src="./img/aurudition/logo.png" />
        <p/>

        <a href={state.page.address} target="_blank" rel="noreferrer">
          <button className="button" type="submit">{state.page.name}</button>
        </a>

        <div className='pButton'>
          <p/>
          {state.page.name} is a fun and rewarding online app designed to help musicians learn to play by ear. It works
          both for developing perfect or relative pitch depending on your expectations and the way you use it.
          Pick an instrument, a range, ask for the app to play notes randomly, and try to find them on a piano keyboard.
          <p/>
          <div className='textaligncenter'>
            <img alt='' className="screenIMG" src="./img/aurudition/auruditionscreen1-min.png" />
          </div>
          <p/>
          {state.page.name} works best on Android and PC. Some platforms and browsers, such as iPhone's Safari, might
          have trouble playing the audio. The design is also not optimized for iPhones.
          <p/>
          Github: <a href="https://github.com/Kamille-Ligula/aurudition" target="_blank" rel="noreferrer">https://github.com/Kamille-Ligula/aurudition</a>
        </div>

        <p/>
        <a href={state.page.address} target="_blank" rel="noreferrer">
          <button className="button" type="submit">{state.page.name}</button>
        </a>
      </div>
    </div>
  )
}
